const lugaresEspaciosColectivos = [
  { id: "CASAINFANCIA", nombre: "Casa de infancia" },
  { id: "CENTRODEPORTIVO", nombre: "Centro deportivo" },
  { id: "COLEGIO_INSTITUTO", nombre: "Colegio / Instituto" },
  { id: "GUARDERIA", nombre: "Guardería" },
  { id: "LUDOTECA", nombre: "Ludoteca" },
  { id: "OTROS", nombre: "Otros" },
];

export default lugaresEspaciosColectivos;
